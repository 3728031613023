<template>
	<PageLayout :title="$t('deposit.title')">
		<div v-if="!loading" class="cashier-box cashier-box--deposit">
			<payment-logo :custom-logo="'cards.svg'" />
			<div class="small-width overflow-visible">
				<payment-wallet />
			</div>

			<div class="line-separator-wrapper">
				<div class="line-separator" />
			</div>

			<amount-buttons
				:buttons-value="currentMethod.predefinedDepositAmounts"
				:form-amount-value="form.amount || currentMethod.predefinedDepositAmounts[0]"
				:currency="'USD'"
				:form-errors="formErrors['amount']"
				:current-method="currentMethod"
				@set-button-value="setButtonValue"
				@input-amount-value-validation="setFormValidation"
			/>
			<span class="amount-error">{{ formErrors.amount }}</span>
			<div>
				<MaterialInput
					id="formAddress"
					v-model="form.address"
					type="text"
					label="Address"
					:error="formErrors.address"
				/>
				<MaterialInput id="formState" v-model="form.state" type="text" label="State" :error="formErrors.state" />
				<MaterialInput id="formCity" v-model="form.city" type="text" label="City" :error="formErrors.city" />
				<MaterialInput id="formZip" v-model="form.zip" type="text" label="Zip" :error="formErrors.zip" />
			</div>
			<button type="button" class="button primary-button deposit center" :disabled="!form.validated" @click="pay()">
				{{ $t('deposit.top-up') }}
			</button>

			<div v-if="loading" class="loader-wrapper">
				<loader />
			</div>
		</div>
		<div v-if="!loading" class="method-description-cashier">
			{{ $t(`deposit.method-descriptions.brcards`) }}
		</div>
	</PageLayout>
</template>
<script>
import { mapGetters } from 'vuex';
import PageLayout from '@/components/page-layout/PageLayout';
import MaterialInput from '@/components/material-input/MaterialInput';
import apiClient from '../../../../api';

const loader = () => import('../../../../shared/loader/Loader.vue');
const paymentLogo = () => import('../../../../shared/payment-logo/PaymentLogo');
const amountButtons = () => import('../../../../shared/amount-buttons/AmountButtons');
const paymentWallet = () => import('../../../../shared/payment-wallet/PaymentWallet');

export default {
	name: 'DepositPaypound',
	components: {
		paymentLogo,
		PageLayout,
		amountButtons,
		MaterialInput,
		loader,
		paymentWallet,
	},
	data() {
		return {
			loading: false,
			form: {
				amount: null,
				validated: true,
				address: null,
				state: null,
				city: null,
				zip: null,
			},
			formErrors: {
				amount: null,
				address: null,
				state: null,
				city: null,
				zip: null,
			},
			walletId: null,
		};
	},
	computed: {
		...mapGetters(['isEmbedded', 'getUser']),
		currentMethod() {
			return this.$store.getters.getCurrentMethod('paypound');
		},
	},
	async mounted() {
		this.loading = false;
		await this.getUserWallet();
	},
	methods: {
		async getUserWallet() {
			try {
				const { id } = await apiClient.fetchFullWallet(this.$route.params.walletId);
				this.walletId = id;
			} catch (e) {
				this.error = 'wallets.failed-to-load-wallet';
			} finally {
				this.loading = false;
			}
		},
		async createTransaction(walletId, amount, currency, address, state, city, zip) {
			const response = await apiClient.paypoundCreateTransaction({
				walletId,
				amount,
				currency,
				address,
				state,
				city,
				zip,
			});

			if (response.status === 201) {
				return response.data;
			}
			throw new Error('Invalid response from create transaction');
		},
		validate() {
			this.formErrors.address = null;
			this.formErrors.state = null;
			this.formErrors.city = null;
			this.formErrors.zip = null;

			let valid = true;

			if (!this.form.address) {
				this.formErrors.address = this.$t('payments.form-errors.empty-address');
				valid = false;
			}
			if (!this.form.state) {
				this.formErrors.state = this.$t('payments.form-errors.empty-state');
				valid = false;
			}
			if (!this.form.city) {
				this.formErrors.city = this.$t('payments.form-errors.empty-city');
				valid = false;
			}
			if (!this.form.zip) {
				this.formErrors.zip = this.$t('payments.form-errors.empty-zip');
				valid = false;
			}

			return valid;
		},
		async pay() {
			const valid = this.validate();
			if (!valid) {
				return;
			}

			const currency = 'USD';

			try {
				this.loading = true;
				const transaction = await this.createTransaction(
					this.walletId,
					this.form.amount,
					currency,
					this.form.address,
					this.form.state,
					this.form.city,
					this.form.zip
				);

				window.location.replace(transaction.redirectUrl);
			} catch (e) {
				if (e.response?.status === 422) {
					const { errors } = e.response.data;
					errors.forEach(({ param, msg }) => {
						this.formErrors[param] = msg;
					});
					return;
				}
				const walletId = this.$route.params.walletId;

				this.$router.push({
					path: `/payments/deposit/${walletId}/confirmation`,
					query: { status: 'error' },
				});
			} finally {
				this.loading = false;
			}
		},
		setButtonValue(event) {
			this.form.amount = event;
		},
	},
};
</script>

<style scoped>
#perfectmoneyRedir {
	visibility: hidden;
}
</style>
